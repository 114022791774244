import React from 'react'
import { WaveSection } from "@components/common"
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react'

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  >svg{
    opacity:0.7;
  }
`;
const StatementText = styled.p`
  text-align:center;
  max-width:min(80vw, 700px); 
  font-family:'Romana Becker Regular',serif;
  font-size:20px;
  font-weight:800;
`;

const Name = styled.p`
  font-family:'Poppins';
  color:#00000099;
  margin:0;
`;

const Padding = styled.div`
  padding:50px 0;
`;

 const params = {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: false
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
  }

function Statement() {
  const { t } = useTranslation(['pages'])

  return (
    <WaveSection>
      <Padding>
        <Swiper  {...params}> 
          {t('pages:lovense.statements', {returnObjects: true}).map(({statement, person},index) => 
            <SwiperSlide key={index}>
              <Wrapper>
                <svg width="60" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.27667 41.7365C1.84333 38.0899 0 33.9999 0 27.3699 0 15.7032 8.19 5.24654 20.1.0765381L23.0767 4.66987C11.96 10.6832 9.78667 18.4865 8.92 23.4065c1.79-.9266 4.1333-1.25 6.43-1.0366 6.0133.5566 10.7533 5.4933 10.7533 11.63 0 3.0942-1.2291 6.0616-3.4171 8.2496-2.1879 2.1879-5.1553 3.417-8.2495 3.417-3.5767 0-6.9967-1.6333-9.16003-3.93Zm33.33333 0c-3.4333-3.6466-5.2767-7.7366-5.2767-14.3666 0-11.6667 8.19-22.12336 20.1-27.2933619L56.41 4.66987C45.2933 10.6832 43.12 18.4865 42.2533 23.4065c1.79-.9266 4.1334-1.25 6.43-1.0366 6.0134.5566 10.7534 5.4933 10.7534 11.63 0 3.0942-1.2292 6.0616-3.4171 8.2496-2.1879 2.1879-5.1554 3.417-8.2496 3.417-3.5767 0-6.9967-1.6333-9.16-3.93Z" fill="#FFF"/></svg>
                <StatementText>{statement}</StatementText>
                <Name>{person}</Name>
              </Wrapper>
            </SwiperSlide>
          )}
        </Swiper> 
      </Padding>
    </WaveSection>
  )
}

export default Statement