import React from 'react'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';



const Wrapper = styled.div`
  display: flex;
  justify-content:center;
  flex-wrap:wrap;
  margin-bottom:100px;
`;


const HalfWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  width:500px;
`;

const ListItem  = styled.div`
  display:flex;
  align-items:center;
`;

const CounterTop = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative ;
  background:#F19D9A;
  height:40px;
  width:40px;
  border-radius:10px;
  color:white;
  margin-right:20px;
  font-family:"Poppins";
`;

const CounterBottom = styled.div`
  position:absolute;
  background:#FDD4CD;
  top:0;
  height:40px;
  width:40px;
  transform:rotate(45deg);
  border-radius:10px;
`

const ListInfo = styled.div`
  max-width: 400px;
  >h1{
    font-size:20px;
  }
  >p{
    font-size:16px;
  }
`;


const Video = styled.video`
  width: 100%;
  max-height: 100%;
`;

const VideoWrapper = styled.div`
  max-width:90%;
  margin:0;
  padding:0;
`;

const PointsWrapper = styled.div`
  display:block;
  max-width:90%;
`;

const CounterWrapper = styled.div`
  position:relative ;

`;

const Explainer = () => {
  const { t } = useTranslation(['pages'])
  return (
    <Wrapper>
      <HalfWrapper>
        <VideoWrapper>
          <Video  width="320" height="240" controls> 
            <source src="/videos/lovense-ad-connect.mp4#t=0.5" type="video/mp4">
            </source>
          </Video>
        </VideoWrapper>
      </HalfWrapper>
      <HalfWrapper>
        <PointsWrapper>
          {t('pages:lovense.explainer', {returnObjects: true}).map(({title, text},index) =>
            <ListItem>
              <CounterWrapper>
                <CounterBottom/>
                <CounterTop>0{index +  1}</CounterTop>
              </CounterWrapper>
              <ListInfo>
                <h1>{title}</h1>
                <p>{text}</p>
              </ListInfo>
            </ListItem>
          )}
        </PointsWrapper>
      </HalfWrapper>

    </Wrapper>
  )
}

export default Explainer