import React, {useContext, useState, useEffect} from 'react'
import HomeHeroSection from '../components/lovense/HomeHeroSection'
import HeroMenu  from '@menus/HeroMenu'
import { useTranslation  } from 'react-i18next'
import Explainer from '../components/lovense/Explainer'
import Wave from  '../components/lovense/Wave'
import { StorySlider } from '@common'
import { graphql, navigate } from "gatsby"
import { useObjectifyStoryArray } from "@hooks/useObjectifyStoryArray"
import Statement from '../components/lovense/Statement'
import styled from 'styled-components';
import Faq from '../components/lovense/Faq'
import { Button } from '@common'
import BackToAppButton from "@common/BackToAppButton"
import { FirebaseContext } from '@components/Firebase'
import Seo from "@components/Seo";


const SliderTitleWrapper = styled.div`
  margin-top:80px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  text-align:center;
  >p{
    max-width:500px;
    color:#00000099;
  }
  >h2{
    max-width:500px;
    margin:0;
  }
`;

const MarginHelper = styled.div`
  margin-top:100px;
`;

const ButtonWrapper = styled.div`
  display:grid;
  place-items:center;
  margin-bottom:50px;
`;

function Lovense(props) {
  const { t, i18n } = useTranslation(['pages','translations'])
  const storiesObject = useObjectifyStoryArray(props.data.allStory)
  const {user} = useContext(FirebaseContext)
 
  useEffect(()=>{
    // try to bandaid the not showing list in collapsible
    i18n.changeLanguage(props.pageContext.lang)
  },[])



  return (
    <>
      {user && <BackToAppButton/>}
       <Seo
        title={t('pages:lovense.pageTitle')}
        description={t('pages:lovense.metaDesc')}
      />
      <HeroMenu/>
      <HomeHeroSection 
        headerTitle={t('pages:lovense.headerTitle')} 
        headerText={t('pages:lovense.headerText')} 
        ctaText={t('pages:lovense.buttonText')}
      />
      <Explainer></Explainer>
      <Wave/>
      <SliderTitleWrapper>
        <h2>{t('pages:lovense.sliderTitle')}</h2>
        <p>{t('pages:lovense.sliderText')}</p>
      </SliderTitleWrapper>
      <StorySlider
        stories={storiesObject} 
        contentIds={t('pages:lovense.sliderIds')}
        title= {""}
        subtitle={""}
        reverseTitle={true}
      />
      <MarginHelper/>
      <Statement/>
      <Faq/>
      <ButtonWrapper>
        <Button onClick={()=> navigate(t('translations:urls.register'))} center>{t('pages:lovense.buttonText')}</Button>
      </ButtonWrapper>
    </>
  )
}

export default Lovense

export const query = graphql`query StoriesLovenseQuery($lang: String, $isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!) {
  allStory(filter: {languages: {in: [$lang]}, id: {in: ["L4Y1gzuAovD0SbOdvQ9a","4jxe1ee6ooMrdxLcaYUK","4zrAEHxN5md5PF6ZeSuY","DqkH83Og8O5dd8ldaviz","X4EXpnIPrQddi76jlaoI","ejD2C34YRnHHiPT8iTwA","fa2otSr7QdynrDBjhRxY","jnD13URgqEsGYDj1mfzq","k4pzUJT6TyZFzJbxKe8M","mTCd8BfrgGKIpqeqq2HJ","4jxe1ee6ooMrdxLcaYUK","4zrAEHxN5md5PF6ZeSuY","DqkH83Og8O5dd8ldaviz","L4Y1gzuAovD0SbOdvQ9a","X4EXpnIPrQddi76jlaoI","ejD2C34YRnHHiPT8iTwA","fa2otSr7QdynrDBjhRxY","jnD13URgqEsGYDj1mfzq","mTCd8BfrgGKIpqeqq2HJ","r6g493HeFsg4ms8tLFjP"]}}) {
    edges {
      node {
        audiodesiresOriginal
        id
        dateCreated
        en @include(if: $isEn) {
          featured
          premiumContent
          published
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        es @include(if: $isEs) {
          featured
          premiumContent
          published
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        de @include(if: $isDe) {
          featured
          premiumContent
          published
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        ...sliderImageLanding
        mainFantasy {
          id
          en @include(if: $isEn) {
            fantasy
          }
          es @include(if: $isEs) {
            fantasy
          }
          de @include(if: $isDe) {
            fantasy
          }
        }
        fantasies {
          id
          en {
            fantasy
            slug
          }
          es @include(if: $isEs) {
            fantasy
            slug
          }
          de @include(if: $isDe) {
            fantasy
            slug
          }
        }
        genre {
          id
          en  @include(if: $isEn) {
            genre
          }
          es  @include(if: $isEs) {
            genre
          }
          de  @include(if: $isDe) {
            genre
          }
          id
        }
        scenario {
          en @include(if: $isEn) {
            scenario
            slug
          }
          es @include(if: $isEs) {
            scenario
            slug
          }
          de @include(if: $isDe) {
            scenario
            slug
          }
        }
      }
    }
  }
}
` 

