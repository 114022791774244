import styled from 'styled-components'
import React from "react"
import {Button} from '../common/Button';
import {navigate} from "gatsby";
import { useTranslation } from 'react-i18next'
import { WaveSection } from "@components/common"
import image1 from "../../images/lovense/landingpage-pic-1.png"

const Wrapper = styled.div`
  display: flex;
  justify-content:center;
  flex-wrap:wrap;
  gap: 50px;
  padding:50px 0;
`;

const HalfWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  width:500px;
  >h2{
    margin:0;
    max-width:min(80vw, 700px); 
    @media (max-width: 1100px) {
      text-align:center;
    }
  }
`;

const Text = styled.p`
  margin:0;
  max-width:min(80vw, 700px); 
  @media (max-width: 1100px) {
    text-align:center;
     margin:20px 0;
  }
`

const Image = styled.img`
  max-width:90%;
`;

const Requirements = styled.p`
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color:#272722;
  opacity:0.6;
  margin-top:20px;
  max-width:90%;
`

const  Wave = (props) => {
  const { t } = useTranslation('pages')


return (  
<WaveSection>
    <Wrapper>
      <HalfWrapper>
        <Image src={image1} alt="Lovense Toys" />
        <Requirements>*{t('pages:lovense.requirements')}</Requirements>
      </HalfWrapper>
      <HalfWrapper>
        <h2>{t('pages:lovense.waveTitle')}</h2>
        <Text dangerouslySetInnerHTML={{ __html: t('pages:lovense.waveText')}}></Text>
        <Text></Text>
        <a target='_blank' rel="noopener noreferrer" href={t('pages:lovense.discountLink')}><Button inverted >{t('pages:lovense.waveButton')}</Button></a>
      </HalfWrapper>
    </Wrapper>
  </WaveSection>
  )

}

export default Wave
