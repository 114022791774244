import React from 'react'
import { Collapsible, Button } from '@common'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const FAQWrapper = styled.div`
  max-width:800px;
  margin: 100px auto 50px auto;
  display:flex;
  flex-direction:column;
  justify-content:center;
  @media ${props => props.theme.tabletBelow}{
    max-width:90%;
  }
`

function Faq() {
  const { t } = useTranslation(['pages'])

  return (
    <FAQWrapper>
      {t('pages:lovense.faq', {returnObjects: true}).map(({question, answer},index) => 
        <Collapsible key={index} question={question} answer={answer}/>
      )}
    </FAQWrapper>
  )
}

export default Faq